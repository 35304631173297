import React from "react"
import Layout, {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import FeaturedRecipes from "../components/Recipe/FeaturedRecipes"
import fonts from "../styles/fonts"
import colors from "../styles/colors"
import breakpoints from "../styles/breakpoints"

const Heading = styled.h2`
  ${fonts.workSansBold}
  line-height: 1.104;
  margin: 2rem 0;
  font-size: calc(2.4vw + 1rem);
  font-weight: 900;
  @media (min-width: 1600px){
    font-size: 55px;
  }
  color: ${colors.yellow};
  text-align: center;
`
const Content = styled.p`
  color: ${colors.lightGrey};
  line-height: 1.6;
  text-align: center;
  padding-bottom: 17px;
`

const ContentContainer = styled.div`
  width: 75%;
  margin-left: auto;
  margin-right: auto;
`

const Links = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-top: 17px;
  flex-wrap: wrap;
`

const InternalLink = styled(Link)`
  display: block;
  width: calc(33% - 34px);
  padding-left: 17px;
  padding-right: 17px;
  color: ${colors.white};
  margin-bottom: 1rem;
  font-size: calc((1 - 1) * 1.2vw + 1rem);
  text-underline-offset: 0.2em;
  text-align: center;
  line-height: 1.6;

  @media (max-width: ${breakpoints.md}) {
    width: calc(100% - 34px);
  }
`

const PageContainer = styled.div`
  padding-top: calc(4vmax + ${DESKTOP_HEADER_HEIGHT}px);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 6.6vmax;

  @media (max-width: ${breakpoints.md}) {
    padding-top: calc(4vmax + ${MOBILE_HEADER_HEIGHT}px);
  }
`

const TagCookingTemplate = (props) => {
  const { metaTitle, metaDescription, landingPageHeading, landingPageText, socialMediaImage } =
    props.data.contentfulRecipeTag
  const recipes = props.data.allContentfulRecipePage.edges
  const path = props.location.pathname
  const cookingInstructions = props.data.allContentfulCookingInstructions.edges

  let sharingImage = false

  if (socialMediaImage) {
    sharingImage = socialMediaImage.file.url
  }

  return (
    <Layout bg={colors.royalBlue}>
      <Seo title={metaTitle} description={metaDescription} path={path} image={sharingImage}/>
      <PageContainer>
        <ContentContainer>
          <Heading>{landingPageHeading}</Heading>
          {landingPageText ? (
            <Content>{landingPageText.landingPageText}</Content>
          ) : null}
          <Links>
            {cookingInstructions
              .map((instruction, index) => {
                return (
                  <InternalLink to={`/${instruction.node.slug}`} key={index}>
                    {instruction.node.title}
                  </InternalLink>
                )
              })}
          </Links>
        </ContentContainer>
        <Heading>Featured Recipes</Heading>
        <FeaturedRecipes recipes={recipes} />
      </PageContainer>
    </Layout>
  )
}

export default TagCookingTemplate

export const pageQuery = graphql`
  query TagCookingTemplateQuery($slug: String!) {
    contentfulRecipeTag(slug: { eq: $slug }) {
      id
      metaTitle
      metaDescription
      slug
      landingPageHeading
      landingPageText {
        landingPageText
      }
      socialMediaImage {
        file {
          url
        }
      }
    }
    allContentfulRecipePage(
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          id
          image {
            gatsbyImageData
            description
          }
          slug
          category {
            title
            slug
          }
          title
          position
        }
      }
    }
    allContentfulCookingInstructions (
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`
